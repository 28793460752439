<script setup lang="ts">
import { cn } from '@/lib/utils';
import { useVModel } from '@vueuse/core';

const props = defineProps<{
  defaultValue?: string | number;
  modelValue?: string | number;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
});
</script>

<template>
  <textarea
    v-model="modelValue"
    :class="
      cn(
        'flex min-h-20 w-full !text-regular-18 focus-visible:outline-none border border-zinc-200  px-3 py-2 ring-offset-white text-regular-20 !text-iq-grey-dark !bg-iq-beige shadow-iq-inset placeholder:!text-iq-grey-light placeholder:text-regular-20   disabled:cursor-not-allowed disabled:opacity-50 ',
        $attrs.class ?? ''
      )
    "
  />
</template>
